import { TAB_MODE } from '@/utils/constant.js';

export const MuiTab = {
  styleOverrides: {
    root: ({ ownerState: { mode = TAB_MODE.BOTTOM } }) => {
      if (mode === TAB_MODE.V2) {
        return {
          fontSize: 'var(--font-size-sm-1)',
          fontWeight: 700,
          lineHeight: '1.6rem',
          color: '#86868B',
          background: 'transparent',
          borderRadius: '8px',
          textTransform: 'capitalize',
          minHeight: '3.2rem',
          padding: '.8rem 1.2rem',

          '&.Mui-selected': {
            color: '#000',
            border: '1px solid #86868B',
          },
        };
      }
      return {
        '&.Mui-selected': {
          color: 'var(--text-primary)',
        },
        textTransform: 'capitalize',
        color: 'var(--text-secondary)',
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '36px',
      };
    },
  },
};
