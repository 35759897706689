import CheckBox from '@/assets/svg/CheckBox.svg?react';
import IndeterminateIcon from '@/assets/svg/IndeterminateIcon.svg?react';
import Unchecked from '@/assets/svg/Unchecked.svg?react';
import { MUI_THEME } from '@/modules/Theme/constant.js';

export const MuiCheckbox = {
  defaultProps: {
    checkedIcon: <CheckBox />,
    icon: <Unchecked />,
    indeterminateIcon: <IndeterminateIcon />,
  },
  styleOverrides: {
    root: ({ theme }) => {
      const idDark = theme.palette.mode === MUI_THEME['dark-mode'];

      return {
        '&.Mui-disabled svg': {
          opacity: 0.3,
        },
        ...(idDark && {
          '&.Mui-checked svg path:nth-of-type(1)': {
            fill: '#36373F',
          },
          '&.Mui-checked svg path:nth-of-type(2)': {
            fill: '#A4A4A4',
          },
          '& svg rect:nth-of-type(2)': {
            // fill: '#27272C',
            stroke: '#4D4E58',
          },
          '&:hover svg rect:nth-of-type(2)': {
            fill: '#222225',
          },
          '&.Mui-disabled svg rect:nth-of-type(2)': {
            fill: '#222225',
            stroke: '#222225',
          },
          '&.Mui-disabled svg': {
            opacity: 0.3,
          },
          '&.Mui-disabled svg path:nth-of-type(1)': {
            fill: '#222225',
          },
        }),
      };
    },
  },
};
