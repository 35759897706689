import { SELECT_VARIANTS } from '@/utils/materialTheme/constant.js';

export const MuiSelect = {
  defaultProps: {
    MenuProps: {
      sx: {
        '&& .MuiPaper-root': {
          boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.08)',
          borderRadius: '12px',
          overflow: 'hidden',
        },
        '&& .MuiList-root': { padding: '.8rem' },
        '&& .Mui-selected': {
          background: 'var(--light-grey-1-color) !important',
          borderRadius: '4px',
        },
        '&& .MuiMenuItem-root': {
          borderRadius: '4px',
        },
        '&& .MuiMenuItem-root:hover': {
          background: 'var(--light-grey-1-color)',
          borderRadius: '4px',
        },
        '&& .Mui-selected:hover': {
          background: 'var(--light-grey-1-color)',
          borderRadius: '4px',
        },
      },
    },
  },
  styleOverrides: {
    root: {
      [`&.${SELECT_VARIANTS.GREY}`]: {
        background: 'var(--light-grey-1-color)',
        borderRadius: '50px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      [`&.${SELECT_VARIANTS.WHITE}`]: {
        background: 'var(--bg-white)',
        borderRadius: '12px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      [`&.${SELECT_VARIANTS.FILTER}`]: {
        background: 'var(--light-grey-1-color)',
        borderRadius: '24px',
        lineHeight: '24px',
        padding: 0,
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        input: {
          padding: 0,
          lineHeight: '24px',
        },
        select: {
          padding: '0 !important',
          textAlign: 'start',
          lineHeight: '24px',
        },
      },
      '& div.MuiInputBase-input.MuiInputBase-inputSizeSmall': {
        padding: '0.6rem 3.2rem 0.6rem 1.2rem !important',
        minWidth: '15rem',
        lineHeight: '24px',
      },
      '& .MuiInputBase-input': {
        padding: '1.2rem 3.2rem 1.2rem 1.6rem !important',
        minWidth: '15rem',
        textAlign: 'start',
        lineHeight: '24px',
      },
      '& .MuiSelect-standard': {
        paddingLeft: '.4rem !important' /*for lined select*/,
        lineHeight: '24px',
      },
    },
  },
};
