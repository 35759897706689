import { MUI_THEME } from '@/modules/Theme/constant.js';

export const MuiPagination = {
  styleOverrides: {
    root: ({ theme }) => {
      const isLight = theme.palette.mode === MUI_THEME['light-mode'];

      return {
        button: {
          fontSize: '16px',
          fontWeight: 400,
          padding: '12px',
          height: '48px',
          width: '48px',
          margin: 0,
          borderRadius: '100%',
        },
        '& .Mui-selected': {
          background: isLight ? '#F5F5F7' : '#1D1C1C',
          fontSize: '16px',
          lineHeight: '24px',
          color: isLight ? '#4D4E58' : 'var(--surface-light-grey-3)',
        },
        '& .Mui-selected:hover': {
          background: 'var(--bg-light-grey-3)',
          fontSize: '16px',
          lineHeight: '24px',
        },
      };
    },
  },
};
