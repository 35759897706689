import { MUI_THEME } from '@/modules/Theme/constant.js';
import { BUTTON_VARIANTS } from '@/utils/materialTheme/constant.js';

export const MuiIconButton = {
  styleOverrides: {
    root: ({ theme, ...button }) => {
      const isLight = theme.palette.mode === MUI_THEME['light-mode'];

      if (button?.ownerState.variant === BUTTON_VARIANTS.SAVE) {
        return {
          background: isLight ? 'var(--light-grey-1-color)' : '#E0E0E0',
          borderRadius: '50px !important',
          color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          textTransform: 'none',
          padding: '0.6rem',
          ':hover': {
            background: isLight
              ? 'var(--bg-light-grey-3)'
              : 'var(--surface-light-grey-3)',
            color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
            background: 'var(--bg-light-grey-2)',
          },
        };
      }
      if (button?.ownerState.variant === BUTTON_VARIANTS.ICON_BUTTON) {
        return {
          background: isLight ? 'var(--bg-light-gray-1)' : '#E0E0E0',
          borderRadius: '8px !important',
          color: isLight ? 'var(--text-bg-black)' : 'var(--bg-white)',
          textTransform: 'none',
          padding: '1.2rem', // TODO: move to size
          ':hover': {
            background: isLight
              ? 'var(--bg-light-grey-3)'
              : 'var(--surface-light-grey-3)',
            color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
            background: 'var(--bg-light-grey-2)',
          },
        };
      }

      return {
        background: 'transparent',
        borderRadius: '50px !important',
        padding: '0.6rem',
        ':hover': {
          background: isLight
            ? 'var(--bg-light-grey-3)'
            : 'var(--surface-light-grey-3)',
          color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
        },
        ':disabled': {
          color: 'var(--text-secondary)',
          background: 'transparent',
          '& > svg > path': {
            fill: 'var(--text-secondary)',
          },
        },
      };
    },
    sizeXsmall: {},
    sizeSmall: {},
    sizeXm: {},
    sizeMedium: {},
    sizeLarge: {},
  },
};
