import { MUI_THEME } from '@/modules/Theme/constant.js';
import { BUTTON_VARIANTS } from '@/utils/materialTheme/constant.js';

export const MuiButton = {
  styleOverrides: {
    root: ({ theme, ...button }) => {
      const isLight = theme.palette.mode === MUI_THEME['light-mode'];

      if (button?.ownerState.variant === BUTTON_VARIANTS.TERTIARY) {
        return {
          background: isLight ? 'transparent' : 'transparent',
          borderRadius: '50px !important',
          border: isLight
            ? '1px solid var(--bg-light-grey-3)'
            : '1px solid var(--bg-medium-grey-color)',
          color: isLight
            ? 'var(--text-primary)'
            : 'var(--bg-medium-grey-color)',
          textTransform: 'none',
          ':hover': {
            border: isLight
              ? '1px solid var(--border-tetriary)'
              : '1px solid white',
            color: isLight ? 'var(--text-primary)' : 'white',
          },
          ':disabled': {
            color: '#86868B',
          },
          '&.Mui-disabled svg path': {
            fill: '#86868B',
          },
        };
      }
      if (button?.ownerState.variant === BUTTON_VARIANTS.SAVE) {
        return {
          background: isLight ? 'var(--light-grey-1-color)' : '#E0E0E0',
          borderRadius: '50px !important',
          color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          ':hover': {
            background: isLight
              ? 'var(--bg-light-grey-3)'
              : 'var(--surface-light-grey-3)',
            color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
            background: 'var(--bg-light-grey-2)',
            'svg path': {
              fill: '#86868B',
            },
          },
        };
      }
      if (button?.ownerState.variant === BUTTON_VARIANTS.WHITE) {
        return {
          background: isLight ? '#FFF' : '#E0E0E0',
          borderRadius: '50px !important',
          color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          ':hover': {
            background: isLight
              ? 'var(--surface-light-gray-1)'
              : 'var(--surface-light-grey-3)',
            color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
            background: '#FFF',
          },
        };
      }
      if (button?.ownerState.variant === BUTTON_VARIANTS.FILTER) {
        return {
          border: '1px solid #E4E4E4',
          background: isLight ? 'var(--bg-white)' : '#E0E0E0',
          color: 'var(--text-bg-black)',
          textTransform: 'none',

          ':hover': {
            border: '1px solid var(--border-tetriary)',
            background: isLight
              ? 'var(--bg-white)'
              : 'var(--surface-light-grey-2)',
          },
          ':disabled': isLight
            ? {
                opacity: '.95',
                'svg path': {
                  fill: '#86868B',
                },
              }
            : {
                background: 'var(--surface-light-grey-2)',
                color: '#86868B',
              },
        };
      }

      if (button?.ownerState.variant === BUTTON_VARIANTS.SUBMIT) {
        return {
          background: isLight ? 'var(--surface-dark)' : '#fff',
          borderRadius: '50px',
          color: 'var(--bg-white)',
          textTransform: 'none',

          ':disabled': {
            background: 'var(--surface-light-grey-2)',
            color: 'var(--text-secondary)',
          },
          ':active': {
            background: isLight
              ? 'var(--surface-black)'
              : 'var(--surface-light-grey-3)',
          },
          ':focus': {
            background: isLight
              ? 'var(--surface-black)'
              : 'var(--surface-light-grey-3)',
          },
          ':hover': {
            background: isLight
              ? 'var(--surface-black)'
              : 'var(--surface-light-grey-3)',
          },
        };
      }

      if (button?.ownerState.variant === BUTTON_VARIANTS.CANCEL) {
        return {
          background: 'transparent',
          color: 'var(--bg-medium-grey-color)',
          textTransform: 'none',
          borderRadius: '50px',
          ':disabled': {
            // background: 'var(--bg-light-grey-2)',
          },
          ':active': {
            background: 'var(--light-grey-1-color)',
            color: 'var(--text-primary);',
          },
          ':focus': {
            background: 'var(--light-grey-1-color)',
            color: 'var(--text-primary);',
          },
          ':hover': {
            background: 'var(--light-grey-1-color)',
            color: 'var(--text-primary);',
          },
        };
      }

      if (button?.ownerState.variant === BUTTON_VARIANTS.TRANSPARENT) {
        return {
          background: 'rgba(0, 0, 0, 0.4)',
          borderRadius: '32px',
          lineHeight: '2rem',
          padding: '.6rem 1.2rem',
          fontSize: 'var(--font-size-lg)',
          color: 'var(--tetriary-color)',
          textTransform: 'none',
        };
      }

      if (button?.ownerState.variant === BUTTON_VARIANTS.DARK) {
        return {
          background: '#000',
          borderRadius: '8px',
          lineHeight: '2rem',
          fontSize: 'var(--font-size-sm-1) !important',
          fontWeight: 400,
          color: '#F2F2F2',
          textTransform: 'none',
          ':hover': {
            background: '#000',
            color: '#F2F2F2',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
          },
        };
      }

      if (button?.ownerState.variant === BUTTON_VARIANTS.OUTLINED_GREY) {
        return {
          borderRadius: '8px',
          lineHeight: '2rem',
          fontSize: 'var(--font-size-sm-1) !important',
          fontWeight: 400,
          color: '#86868B',
          border: 'thin solid #F5F5F7',
          textTransform: 'none',
          ':hover': {
            color: 'var(--text-secondary)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
          },
        };
      }

      if (button?.ownerState.variant === BUTTON_VARIANTS.TEXT) {
        return {
          background: 'transparent',
          borderRadius: '50px',
          color: 'var(--text-primary)',
          textTransform: 'none',
          ':disabled': {
            color: 'var(--text-secondary)',
          },
          ':hover': {
            background: 'var(--bg-light-grey-3)',
            color: 'var(--text-primary)',
          },
        };
      }

      return {};
    },
    sizeXsmall: {
      lineHeight: '2.4rem',
      fontSize: 'var(--font-size-lg)',
      fontWeight: 400,
      padding: '.6rem 1.6rem',
    },
    sizeSmall: {
      lineHeight: '2.4rem',
      fontSize: 'var(--font-size-lg)',
      fontWeight: 400,
      padding: '1.2rem 2.4rem',
    },
    sizeXm: {
      lineHeight: '1.6rem',
      fontSize: 'var(--font-size-sm-1) !important',
      fontWeight: 400,
      padding: '.8rem 1.2rem',
      borderRadius: '8px !important',
    },
    sizeMedium: {
      height: '4.8rem',
      borderRadius: '24px',
      lineHeight: '2.4rem',
      padding: '12px, 16px, 12px, 16px',
      fontSize: 'var(--font-size-lg)',
    },
    sizeLarge: {
      width: '100%',
      borderRadius: '32px',
      fontWeight: 400,
      fontSize: 'var(--font-size-lg)',
      height: '6.4rem',
    },
  },
};
